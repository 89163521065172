import styled from '@emotion/styled';
import { Fonts, Colors, LabelTextBold } from '@paddl/storybook';

const {
  sec_green_dark,
  sec_red_dark,
  new_grey_dark
} = Colors;

export const ProfileViewsWrapper = styled.div`
  padding: 0 12px 32px 16px;
`;

export const TotalViewWrapper = styled.div`
  ${Fonts.H1};
  margin-top: 6px;
  text-align: center;
`;

const getTrendDirectionColour = (trendDirection) => {
  switch (trendDirection) {
    case 'down':
      return sec_red_dark;
    case 'up':
      return sec_green_dark;
    default:
      return new_grey_dark;
  }
};

const getTrendDirectionArrowRotation = (trendDirection) => {
  switch (trendDirection) {
    case 'down':
      return 'rotate(180deg)';
    case 'up':
      return 'rotate(0deg)';
    default:
      return 'rotate(90deg)';
  }
};

export const TrendWrapper = styled(LabelTextBold)`
  display: flex;
  align-items: flex-end;
  justify-content: right;
  margin-top: 4px;
  color: ${({ trendDirection }) => (
    getTrendDirectionColour(trendDirection)
  )};

  svg {
    width: 16px;
    height: 16px;
    margin-right: 1px;
  transform: ${({ trendDirection }) => (
    getTrendDirectionArrowRotation(trendDirection)
  )};
  fill: ${({ trendDirection }) => (
    getTrendDirectionColour(trendDirection)
  )};
  }

  span {
    margin-top: -4px;
  }
`;
