import {
  ModularPage,
  SnackbarProvider
} from '@paddl/storybook';

import React from 'react';
import {
  MemberAppNavigation_Populated
} from '@paddl/utils-react';
import { MyProfileProvider } from '../../components/Context/MyProfileContext';
import { MyProfilePageContent } from './MyProfilePageContent';
import { MyProfileSideContent } from './MyProfileSideContent';
import { ShareProfileActionButton } from '../Profile/ShareProfileActionButton';
import { MenuItems } from '../../components';
import { ModalContextProvider } from '../../components/Context/ModalContext';

export const MyProfile = () => {
  const pageActionItems = [
    <MenuItems
      key="edit-menu"
    />,
      <ShareProfileActionButton />];

  return (
    <SnackbarProvider>
    <ModalContextProvider>
      <MyProfileProvider>

      <ModularPage
        navigationContent={<MemberAppNavigation_Populated currentPage="profile" />}
        pageName="Profile"
        pageContent={<MyProfilePageContent />}
        pageActionItems={pageActionItems}
        sidebarContent={<MyProfileSideContent />}
      />

      </MyProfileProvider>
    </ModalContextProvider>
    </SnackbarProvider>);
};
