import {
  ImageEditor,
  useWidth
} from '@paddl/storybook';
import {
  shape,
  func
} from 'prop-types';
import React, { useState } from 'react';
import {
  ImageEditorWrapper
} from './EditProfileBannerModal.styles';

export const BannerResizer = ({ onResize, originalFile }) => {
  const width = useWidth();
  const isLargeScreen = width !== 'xs' && width !== 'sm';
  const [isMounted, setIsMounted] = useState(false);

  return (
  <ImageEditorWrapper>
    <ImageEditor
      callback={(result) => {
        if (isMounted) {
          onResize(result);
        }
      }}
      imageUrl={originalFile}
      zoom={1}
      isAvatarStyle={false}
      height={140}
      onMount={() => setIsMounted(true)}
      width={isLargeScreen ? 656 : 432}
    />
  </ImageEditorWrapper>);
};

BannerResizer.propTypes = {
  onResize: func.isRequired,
  originalFile: shape({}).isRequired
};
