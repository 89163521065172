import { ProfileSettings } from '../pages';
import { CreateExperienceRecord } from '../pages/ExperienceRecords/create/CreateExperienceRecord';
import { ExperienceRecord } from '../pages/ExperienceRecords/view/ExperienceRecord';
import { ProfilePageWrapper } from '../pages/Profile/ProfilePageWrapper';

export const routerConfig = [
  {
    path: '/xp/:profileIdOrSlug',
    component: ProfilePageWrapper
  },
  {
    path: '/settings',
    component: ProfileSettings
  },
  {
    path: '/add-experience',
    component: CreateExperienceRecord
  },
  {
    path: '/experience/:experienceRecordId',
    component: ExperienceRecord
  }
];
