import styled from '@emotion/styled';
import { Colors, Fonts, viewportWidthSmall } from '@paddl/storybook';

const {
  new_grey,
  new_grey_light,
  charcoal
} = Colors;

const {
  labelText,
  body
} = Fonts;

export const ModalContentWrapper =  styled.div`
  padding: 0 16px;
  text-align: center;

  @media (max-width: ${viewportWidthSmall}) {
    padding: 0;
  }
`;

export const TitleWrapper = styled.h2`
  ${Fonts.H3};
`;

export const SubtitleWrapper = styled.p`
  ${body};
  margin-top: 16px;
`;

export const ContentWrapper = styled.div`
  ${body};
  color: ${new_grey};
  margin: 28px 0;
  overflow: hidden;

  svg {
    width: 90px;
    height: 90px;
    fill: ${new_grey_light};
  }

  em {
    ${labelText};
    color: ${new_grey};
    display: block;
  }

  p {
    color: ${charcoal};
  }

  .MuiSlider-root {
    margin-bottom: 16px;
  }
`;

export const FooterWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 16px;
  }
`;
