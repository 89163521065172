import { useResource } from '@paddl/utils-react';
import { shape, string } from 'prop-types';
import React from 'react';
import { MyProfile } from '../MyProfile/MyProfile';
import { Profile } from './Profile';

export const ProfilePageWrapper = ({
  match: {
    params: {
      profileIdOrSlug
    }
  }
}) => {
  const { data: myProfileData, isLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/my-profile/`);
  if (isLoading) return null;

  const { urlSlug, profileId } = myProfileData?.profile || {};
  if (urlSlug === profileIdOrSlug || `${profileId}` === profileIdOrSlug) {
    return <MyProfile />;
  }

  return <Profile profileIdOrSlug={profileIdOrSlug} />;
};

ProfilePageWrapper.propTypes = {
  match: shape({
    params: shape({
      profileIdOrSlug: string
    }).isRequired
  }).isRequired
};
