import {
  Hyperlink,
  TextField,
  useSnackbarContext
} from '@paddl/storybook';
import React, { useEffect, useState } from 'react';
import { useResource, useFetchData } from '@paddl/utils-react';
import Button from '@mui/material/Button';
import { useMyProfile } from '../../Context/MyProfileContext';
import { ButtonsWrapper } from '../ProfileSettingsForms.styles';
import { UrlSlugList } from './components';
import { UrlSlugListHeadline, UseProfileIdWrapper } from './ProfileUrlForm.styles';

export const ProfileUrlForm = () => {
  const { showSnackbar } = useSnackbarContext();
  const { profile, isLoading, update  } = useMyProfile();
  const currentUrlSlug = profile?.urlSlug;
  const profileId = profile?.profileId;
  const [profileSettingsForm, setProfileSettingsForm] = useState({
    urlSlug: null
  });
  const { data: suggestionData } = useResource(`${process.env.AT_WORK_API}/v2/slug/suggestions`);

  useEffect(() => {
    if (!isLoading) {
      setProfileSettingsForm({ urlSlug: profile?.urlSlug });
    }
  }, [isLoading, profile]);

  const [isSlugAvailable, setIsSlugAvailable] = useState(true);
  const { fetchData, isLoading: isLoadingIsSlugAvailable } = useFetchData();

  useEffect(() => {
    if (!profileSettingsForm.urlSlug ||
      profileSettingsForm.urlSlug === currentUrlSlug ||
      profileSettingsForm.urlSlug === `${profileId}`) {
      setIsSlugAvailable(true);
    } else {
      fetchData(`${process.env.AT_WORK_API}/v2/slug/${profileSettingsForm.urlSlug}/is-available`).then((data) => {
        setIsSlugAvailable(data.isAvailable);
      });
    }
  }, [currentUrlSlug, fetchData, profileId, profileSettingsForm.urlSlug]);

  const submitSlug = async () => {
    try {
      await update({ urlSlug: profileSettingsForm.urlSlug });
      showSnackbar({ message: 'Profile url successfully updated. Please wait...', type: 'success' });
      setTimeout(() => window.location.reload(), 1000);
    } catch (e) {
      console.error(e);
      showSnackbar({ message: 'Error updating profile url', type: 'error' });
    }
  };

  const {
    urlSlug: newUrlSlug
  } = profileSettingsForm;

  return (
        <>
          <TextField
            label="Profile URL"
            placeholder="example_name"
            isControlled
            value={
              newUrlSlug !== undefined
                ? newUrlSlug
                : currentUrlSlug
            }
            startAdornment="www.paddl.com/xp/"
            hasError={
              !isLoadingIsSlugAvailable &&
              currentUrlSlug !== newUrlSlug &&
              !isSlugAvailable
            }
            errorText="slug is not available"
            helperText="Max of 40 characters."
            onChange={(urlSlug) =>
              setProfileSettingsForm({ ...profileSettingsForm, urlSlug })}
          />
          <UseProfileIdWrapper>
            <Hyperlink
              onClick={() =>
                setProfileSettingsForm({
                  ...profileSettingsForm,
                  urlSlug: `${profileId}`
                })}
            >
              Use your Profile ID: <strong>{profileId}</strong>
            </Hyperlink>
          </UseProfileIdWrapper>
          <UrlSlugListHeadline>Suggestions</UrlSlugListHeadline>
          <UrlSlugList
            slugs={suggestionData?.suggestions || []}
            onClick={(urlSlug) => setProfileSettingsForm({ ...profileSettingsForm, urlSlug })}
          />
          <ButtonsWrapper>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setProfileSettingsForm({ urlSlug: currentUrlSlug })}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={submitSlug}
              disabled={!isSlugAvailable || isLoading || !profileSettingsForm.urlSlug}
            >
              Save Changes
            </Button>
          </ButtonsWrapper>
        </>
  );
};
