import LaunchSharpIcon from '@mui/icons-material/LaunchSharp';
import {
  ActivityBadgeHeader,
  Body,
  Colors,
  Column,
  DividedRow,
  PaperButton,
  SidebarSection
} from '@paddl/storybook';
import {
  hardRedirect
} from '@paddl/utils-js';
import { number, string } from 'prop-types';

import {
  useResource
} from '@paddl/utils-react';
import React from 'react';

export const ExperienceRecordSideContent = ({
  skillCount,
  xp,
  experienceVariant
}) => {
  const { data: profileData, isLoading: isProfileLoading } = useResource(`${process.env.AT_WORK_API}/v1/my-profile`);

  const { urlSlug, profileId } = profileData?.profile || {};

  return (<>
      <SidebarSection>
        <Column style={{ gap: '32px' }}>
          <ActivityBadgeHeader
            alignment="center"
            type={experienceVariant}
          />
          <PaperButton
            disabled={isProfileLoading}
            onClick={() => hardRedirect('timelineBySlugOrId', { slug: urlSlug, id: profileId })}
          >
            <LaunchSharpIcon sx={{ verticalAlign: 'bottom', fontSize: '24px', paddingRight: '8px' }} />
            <span>View this Experience on your Profile.</span>
          </PaperButton>
        </Column>
      </SidebarSection>
      <SidebarSection>
        <DividedRow>
          <Body style={{
            color: Colors.sec_purple,
            fontWeight: 500

          }}
          >{skillCount} {skillCount === 1 ? 'skill' : 'skills'}
          </Body>
          <Body style={{
            color: Colors.new_grey,
            fontWeight: 500

          }}
          >{xp} XP
          </Body>

        </DividedRow>
      </SidebarSection><SidebarSection />
          </>
  );
};

ExperienceRecordSideContent.defaultProps = {
  skillCount: 0
};

ExperienceRecordSideContent.propTypes = {
  skillCount: number,
  xp: number.isRequired,
  experienceVariant: string.isRequired
};
