import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';

export const ModalContext = React.createContext({ openModal: null, setOpenModal: () => {}, closeModal: () => {} });

export const useModalContext = () => useContext(ModalContext);

export const ModalContextProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(null);

  const modalContext = useMemo(() => {
    return {
      openModal, setOpenModal, closeModal: () => setOpenModal(null)
    };
  }, [openModal, setOpenModal]);

  return  <ModalContext.Provider value={modalContext}>{children}</ModalContext.Provider>;
};

ModalContextProvider.defaultProps = {
  children: null
};

ModalContextProvider.propTypes = {
  children: PropTypes.node
};
