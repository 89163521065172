import {
  func, number, shape, string
} from 'prop-types';
import { Grid } from '@mui/material';
import {
  Artworks, Dropdown, TextField, AutocompleteSelect
} from '@paddl/storybook';
import { useLoginContext } from '@paddl/utils-react';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { FormSection } from './FormSection';

export const DetailsFormSection = (props) => {
  const {
    details, setDetails, commitment, setCommitment
  } = props;
  const [providerSuggestions, setProviderSuggestions] = useState([]);
  const { idToken } = useLoginContext();
  const updateProviderSuggestions = useCallback((enteredProvider) => {
    axios.get(`${process.env.AT_WORK_API}/v2/autocomplete/provider-name`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      params: {
        substring: enteredProvider
      }
    }).then((response) => {
      setProviderSuggestions(response.data.map((suggestion) => ({ value: suggestion, title: suggestion })));
    }).catch((e) => { console.error(e); });
  }, [idToken]);

  const onSelectProvider = useCallback((item) => {
    setDetails({ ...details, providerName: item.value || item.inputValue });
  }, [details, setDetails]);

  return (

<FormSection icon={<Artworks.details />}>
<TextField
  label="Title"
  placeholder="e.g How to..."
  onChange={(title) => setDetails({ ...details, title })}
  isRequired
/>
<AutocompleteSelect
  label="Provider"
  placeholder="e.g. Mr. Beast"
  options={providerSuggestions}
  isRequired
  action={onSelectProvider}
  allowCreateNewOption
  getInputValue={updateProviderSuggestions}
/>
<TextField
  label="Description"
  placeholder="e.g. what was this activity about?"
  onChange={(description) => setDetails({ ...details, description })}
  isRequired
/>

<Grid container spacing={2}>
    <Grid item xs={12} sm={4}>

        <TextField
          label="Time Commitment"
          type="number"
          placeholder="5"
          onChange={(commitmentValueString) => setCommitment(
            { ...commitment,  value: parseInt(commitmentValueString, 10) }
          )}
          isRequired
        />
    </Grid>
    <Grid item xs={12} sm={4}>
        <Dropdown
          defaultValue={'minute'}
          onChange={(unit) => setCommitment(
            {
              ...commitment, unit
            })}
          items={[
            { value: 'minute', text: 'Minutes' },
            { value: 'hour', text: 'Hours' },
            { value: 'day', text: 'Days' },
            { value: 'week', text: 'Weeks' },
            { value: 'year', text: 'Years' }
          ]}
        />
    </Grid>
    <Grid item xs={12} sm={4}>
        <Dropdown
          defaultValue={'total'}
          onChange={(period) => setCommitment(
            {
              ...commitment, period
            })}
          items={[
            { value: 'total', text: 'Total' },
            { value: 'day', text: 'Per Day' },
            { value: 'week', text: 'Per Week' },
            { value: 'year', text: 'Per Year' }
          ]}
        />

    </Grid>
</Grid>
</FormSection>
  );
};

DetailsFormSection.propTypes = {
  commitment: shape({
    value: number,
    unit: string,
    period: string
  }).isRequired,
  details: shape({
    title: string,
    description: string,
    providerName: string
  }).isRequired,
  setCommitment: func.isRequired,
  setDetails: func.isRequired
};
