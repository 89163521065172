import React, { useState, useContext, useEffect } from 'react';
import {
  trackEvent, trackIdentity
} from '@paddl/utils-js';
import { TextField } from '@paddl/storybook';
import Button from '@mui/material/Button';
import { EditModalWrapper } from './EditModalWrapper';
import { ModalContext } from '../Context/ModalContext';
import { useMyProfile } from '../Context/MyProfileContext';

const id = 'edit-profile-summary';
export const EditProfileSummary = () => {
  const { openModal, closeModal } = useContext(ModalContext);
  const isOpen = openModal === id;
  const { profile, update, isLoading } = useMyProfile();

  const [summary, setSummary] = useState('');

  useEffect(() => {
    setSummary(profile.aboutMe);
  }, [profile]);

  const submitProfileSummaryData = () => {
    update({ aboutMe: summary }).then(() => {
      trackEvent('Updated profile summary');
      trackIdentity(profile.profileId, { summary });
      closeModal();
    });
  };

  return (
    <EditModalWrapper
      isLarge
      data-test-id="modal-wrapper"
      hasBrandedColour={false}
      isOpen={isOpen}
      setIsOpen={closeModal}
      title="Edit About me"
      subtitle="Share more of your story, goals and aspirations."
      cancelButton={<Button onClick={closeModal} variant="text">Cancel</Button>}
      saveButton={<Button disabled={isLoading} variant="contained" onClick={submitProfileSummaryData}>Save</Button>}
    >
      <TextField
        multiline
        isOutlined
        maxLength={1500}
        label="About me"
        data-test-id="about-me"
        placeholder="Share more of your story, goals and aspirations."
        onChange={(value) => setSummary(value)}
        helperText={`Max 1500${summary && summary.length  > 0 ? `/${summary.length}` : ''} Characters`}
        defaultValue={summary || ''}
      />
    </EditModalWrapper>
  );
};

EditProfileSummary.modalId = id;
