import { Grid } from '@mui/material';
import {
  Modal, ActivityCard, ActivityCategoryButton, H2, Body
} from '@paddl/storybook';
import { activityCategories, getActivitiesByCategoryName } from '@paddl/utils-js';
import PropTypes, {
  func
} from 'prop-types';
import React, { useState } from 'react';
import { useModalContext } from '../../../components/Context/ModalContext';

const modalId = 'select-type-modal';

const unsupportedCategories = ['employment'];

const SelectCategoryPage = ({ onSelectCategory }) => {
  // TODO: stop filtering the employment category once we support some emplaoyement varients, eg. internship
  const categories =  activityCategories()
    .map(({ id }) => id)
    .filter((category) => !unsupportedCategories.includes(category));

  return (
    <Grid
      container
      spacing={2}
    >
    {categories.map((category) => (
        <Grid key={category} item xs={6} md={4}>
        <ActivityCategoryButton
          type={category}
          onClick={() => onSelectCategory(category)}
        />
        </Grid>))}

    </Grid>);
};

SelectCategoryPage.propTypes = {
  onSelectCategory: PropTypes.func.isRequired
};
const supportedVariants = [
  'diploma',
  'bachelorDegree',
  'masterDegree',
  'phd',
  'workshop',
  'hackathon',
  'prize',
  'volunteer',
  'membership',
  'meetup',
  'video',
  'article'
];
const SelectTypePage = ({ onSelectType, category }) => {
  const types = getActivitiesByCategoryName(category)
    .activities
    .map(({ title }) => title)
    .filter((type) => supportedVariants.includes(type));

  return (
    <Grid
      container
      spacing={2}
    >
    {types.map((type) => (
        <Grid key={type} item xs={6} md={4}>
        <ActivityCard
          type={type}
          onClick={() => onSelectType(type)}
        />
        </Grid>))}

    </Grid>);
};

SelectTypePage.propTypes = {
  category: PropTypes.string.isRequired,
  onSelectType: PropTypes.func.isRequired
};

SelectCategoryPage.pageId = 'select-category-page';
export const SelectTypeModal = ({
  onSelect
}) => {
  const { openModal, closeModal } = useModalContext();
  const [page, setPage] = useState(SelectCategoryPage.pageId);
  const [category, setCategory] = useState(null);

  return (
  <Modal
    hasBrandedColour={false}
    isOpen={openModal === modalId}
    handleExit={closeModal}
    isLarge
  >
    <H2 style={{ textAlign: 'center' }}>Select an Experience Type</H2>
    <Body style={{ textAlign: 'center' }}>Choose a type that best describes your experience.</Body>
    {page === SelectCategoryPage.pageId && <SelectCategoryPage onSelectCategory={(_category) => {
      setPage(SelectTypePage.pageId);
      setCategory(_category);
    }}
    />}

    {page === SelectTypePage.pageId && <SelectTypePage
      category={category}
      onSelectType={(type) => {
        closeModal();
        setPage(SelectCategoryPage.pageId);
        onSelect(type);
      }}
    />}
  </Modal>);
};

SelectTypeModal.propTypes = {
  onSelect: func
};

SelectTypeModal.defaultProps = {
  onSelect: () => {}
};

SelectTypeModal.modalId = modalId;
