import { useResource, TimelineTabPanel } from '@paddl/utils-react';
import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import Alert from '@mui/material/Alert';

const _MyTimeline = () => {
  const { data, isLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/my-profile/timeline`);

  if (isLoading) return null;

  return <TimelineTabPanel timeline={data?.timeline} />;
};

export const MyTimeline = withErrorBoundary(_MyTimeline, {
  fallback: <Alert severity="error">Error rendering your timeline. </Alert>
});
