import { Row, useWidth } from '@paddl/storybook';
import React from 'react';
import styled from '@emotion/styled';
import { node } from 'prop-types';

const IconWrapper = styled.div`
  width: 48px;
  height: 48px; 
  padding-top: 8px;

  svg {
    width: 48px;
    height: 48px;
  }
`;
export const FormSection = ({ icon, children }) => {
  const width = useWidth();
  const showIcon = width !== 'xs';

  return <Row style={{ width: '100%', alignItems: 'flex-start' }}>
    {showIcon &&  <IconWrapper>{icon}</IconWrapper>}
    <div style={{    flexGrow: 1 }}>{children}</div>
         </Row>;
};

FormSection.propTypes = {
  icon: node.isRequired,
  children: node.isRequired
};
