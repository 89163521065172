import {
  IconButton
} from '@paddl/storybook';
import React, { useContext } from 'react';
import { ModalContext } from '../../components/Context/ModalContext';
import { ShareProfileModal } from '../../components';

export const ShareProfileActionButton = () => {
  const { setOpenModal } = useContext(ModalContext);

  return <IconButton
    onClick={() => setOpenModal(ShareProfileModal.modalId)}
    icon="share"
    variant="plained"
    size="small"
    color="grey"
    key="share"
  />;
};
