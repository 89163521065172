import React, { useState } from 'react';
import { Switch, useSnackbarContext } from '@paddl/storybook';
import { urlStringForPathKey } from '@paddl/utils-js';
import { useAuthorisation } from '@paddl/utils-react';
import {
  shape
} from 'prop-types';
import axios from 'axios';
import { Button } from '@mui/material';
import LaunchSharpIcon from '@mui/icons-material/LaunchSharp';
import {
  ToggleWrapper,
  FormLabel,
  SectionDivider,
  ButtonWrapper
} from './ProfileVisibilityForm.styles';
import { useMyProfile } from '../../Context/MyProfileContext';

export const ProfileVisibilityForm = ({
  profileSettings: { profilePublicVisible }
}) => {
  const [formState, setFormState] = useState({
    profilePublicVisible
  });

  const { showSnackbar } = useSnackbarContext();
  const { profile: { profileId, urlSlug } } = useMyProfile();

  const { withAuthorisationHeaders } = useAuthorisation();

  const updateProfileVisibility = async (newFormState) => {
    const previousFormState = { ...formState };
    setFormState(newFormState);
    withAuthorisationHeaders((headers) => {
      const body = {
        profilePublicVisible: newFormState.profilePublicVisible
      };
      axios.patch(`${process.env.API_PREFIX_AT_WORK}/my-profile/settings`, body, { headers }).then(() => {
        showSnackbar({ message: 'Profile visibility successfully updated.', type: 'success' });
      }).catch((error) => {
        console.error(error);
        setFormState(previousFormState);
        showSnackbar({ message: 'Error updating profile visibility', type: 'error' });
      });
    });
  };

  return (
        <>
          <ToggleWrapper>
            <FormLabel bold>Show Profile Publicly</FormLabel>
            <Switch
              value={formState.profilePublicVisible}
              data-test-id="master-switch"
              onClick={(value) => updateProfileVisibility({ ...formState, profilePublicVisible: value })}
            />
          </ToggleWrapper>
          <SectionDivider />
          <ButtonWrapper>
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              href={urlStringForPathKey('publicProfileBySlug', {
                slug: urlSlug || profileId
              })}
              target="_blank"
            >
              <LaunchSharpIcon sx={{ verticalAlign: 'bottom', fontSize: '24px', paddingRight: '8px' }} />
              See your Public Profile
            </Button>
          </ButtonWrapper>
        </>
  );
};

ProfileVisibilityForm.propTypes = {
  profileSettings: shape({})
};

ProfileVisibilityForm.defaultProps = {
  profileSettings: {}
};
