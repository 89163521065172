import {
  Artworks,
  Datepicker_V2
} from '@paddl/storybook';
import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@mui/material/Grid';
import { Checkbox, FormControlLabel } from '@mui/material';
import { DateTime } from 'luxon';
import { FormSection } from './FormSection';

export const ProgressFormSection = ({
  progress, setProgress
}) => {
  return (
    <FormSection icon={<Artworks.progress />}>
                            <Grid container spacing={2}>
                            <Grid item xs={6}>

                            <Datepicker_V2
                              label="Start"
                              value={progress.startDate ? progress.startDate?.toJSDate() : null}
                              onChange={(startDate) => setProgress(
                                { ...progress, startDate: DateTime.fromJSDate(startDate).startOf('day') }
                              )}
                            />
                            </Grid>

                            <Grid item xs={6}>
                            <Datepicker_V2
                              label="End"
                              value={progress.endDate ? progress.endDate?.toJSDate() : null}
                              onChange={(endDate) => setProgress(
                                { ...progress, endDate: DateTime.fromJSDate(endDate).endOf('day') }
                              )}
                            />
                            </Grid>

                            <Grid item xs={6} />

                            <Grid item xs={6}>
                            <FormControlLabel
                              control={<Checkbox onChange={(event) => {
                                const isCurrent = event.target.checked;
                                setProgress(
                                  { ...progress, isCurrent }
                                );
                              }}
                              />}
                              label="Is Current"
                            />
                            </Grid>

                            </Grid>
    </FormSection>
  );
};

ProgressFormSection.propTypes = {
  progress: PropTypes.shape({
    endDate: PropTypes.string,
    startDate: PropTypes.string
  }).isRequired,
  setProgress: PropTypes.func.isRequired
};
