import styled from '@emotion/styled';
import { Fonts, viewportWidthSmall } from '@paddl/storybook';

const { H2 } = Fonts;

export const ShareModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  h2 {
    ${H2};
    margin: 6px 0 24px;
  }

  .avatar-wrapper {
    margin-top: -88px;
  }
`;

export const UrlShareWrapper = styled.div`
 margin: 0 0 24px 0;
`;

export const ShareModalContentWrapper = styled.div`
  padding: 0 38px;
  @media (max-width: ${viewportWidthSmall}) {
    padding: 0 8px;
  }
`;
