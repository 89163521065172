import Slider from '@mui/material/Slider';
import {
  Icons,
  Colors
} from '@paddl/storybook';
import {
  func
} from 'prop-types';
import React from 'react';

import styled from '@emotion/styled';

const { add: PlusIcon, subtract: MinusIcon } = Icons;

const {
  grey_lighter
} = Colors;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  [class^='Sliderstyles__SliderWrapper'] {
    width: 100%;
    margin: 0 24px;

    .MuiSlider-root {
      margin-bottom: 0;
    }
  }

  svg {
    width: 20px;
    fill: ${grey_lighter};
  }
`;

export const ZoomSlider = ({ setZoom }) => {
  return (
    <>
      <SliderWrapper>
        <MinusIcon className="icon" />
        <Slider
          data-test-id="slider"
          onChange={(event) => setZoom(Number(event.target.value))}
          defaultValue={1.2}
          step={0.1}
          min={1}
          max={2.5}
        />
        <PlusIcon className="icon" />
      </SliderWrapper>
      <em>Slide to zoom and drag your image to adjust cropping.</em>
    </>);
};

ZoomSlider.propTypes = {
  setZoom: func.isRequired
};
