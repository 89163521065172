import React, {
  createContext, useState, useEffect, useMemo, useContext,
  useCallback
} from 'react';
import t from 'prop-types';
import axios from 'axios';
import { useAuthorisation } from '@paddl/utils-react';

const emptyProfile = {
  profileId: null, fullName: '', title: '', avatarUrl: '', bannerUrl: '', links: [], aboutMe: '', urlSlug: null
};

export const MyProfileContext = createContext({ profile: emptyProfile, update: () => Promise.resolve(), isLoading: true });
export const useMyProfile = () => useContext(MyProfileContext);

export const MyProfileProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState();
  const { withAuthorisationHeaders } = useAuthorisation();

  const refresh = useCallback(() => {
    withAuthorisationHeaders((headers) =>
      axios.get(`${process.env.API_PREFIX_AT_WORK}/my-profile/`, { headers }))
      .then((response) => {
        setProfile(response.data.profile);
      }).catch().finally(() => setIsLoading(false));
  }, [withAuthorisationHeaders]);

  const update = useCallback((partialProfile) => {
    setIsLoading(true);

    return withAuthorisationHeaders((headers) =>
      axios.patch(`${process.env.API_PREFIX_AT_WORK}/my-profile/`, partialProfile, { headers })
        .then(() => {
          refresh();
        }));
  }, [refresh, withAuthorisationHeaders]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const value = useMemo(() => {
    return { isLoading, profile: (profile || emptyProfile), update };
  }, [isLoading, profile, update]);

  return (<MyProfileContext.Provider value={value}>
  {children}
          </MyProfileContext.Provider>);
};

MyProfileProvider.propTypes = {
  children: t.node.isRequired
};
