import React, { useState } from 'react';
import { arrayOf, string, func } from 'prop-types';
import { Hyperlink, Icons } from '@paddl/storybook';
import { UrlSlugListWrapper, NameIconWrapper, UrlSlugListItem } from './UrlSlugList.styles';

const SUGGESTION_LIMIT = 6;
const { globe: Globe, incomplete: Incomplete } = Icons;

export const UrlSlugList = ({ slugs, onClick }) => {
  const [dismissedSlugs, setDismissedSlugs] = useState([]);

  return (
    <UrlSlugListWrapper>
      {slugs.filter((slug) => !dismissedSlugs.includes(slug)).map((slug) => (
        <UrlSlugListItem key={slug}>
          <NameIconWrapper>
          <Globe />
          <Hyperlink type="button" data-test-id={`select-${slug}`} onClick={() => onClick(slug)}>
            {slug}
          </Hyperlink>
          </NameIconWrapper>
          <Incomplete data-test-id={`dismiss-${slug}`} onClick={() => setDismissedSlugs([...dismissedSlugs, slug])} />
        </UrlSlugListItem>
      )).slice(0, SUGGESTION_LIMIT)}
    </UrlSlugListWrapper>
  );
};

UrlSlugList.propTypes = {
  slugs: arrayOf(string),
  onClick: func.isRequired
};

UrlSlugList.defaultProps = {
  slugs: []
};
