import styled from '@emotion/styled';

export const BottomInputsWrappers = styled.div`
  display: flex;
  gap: 24px;
`;

export const FieldWrapper = styled.div`
  margin-bottom: 16px;
`;

export const InputWrapper = styled.div`
  width: 50%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding-top: 32px;
`;
