import { ModularPage } from '@paddl/storybook';
import { MemberAppNavigation_Populated } from '@paddl/utils-react';
import { string } from 'prop-types';
import React from 'react';
import {
  ProfileSuggestions
} from '../../components';
import { ModalContextProvider } from '../../components/Context/ModalContext';
import { ProfilePageContent } from './ProfilePageContent';
import { ShareProfileActionButton } from './ShareProfileActionButton';

export const Profile = ({
  profileIdOrSlug
}) => {
  const pageActionItems = [
    <ShareProfileActionButton />
  ];

  return (
    <ModalContextProvider>
      <ModularPage
        sidebarContent={<ProfileSuggestions
          profileIdOrSlug={profileIdOrSlug}
        />}
        pageName="Profile"
        pageContent={<ProfilePageContent
          profileIdOrSlug={profileIdOrSlug}
        />}
        pageActionItems={pageActionItems}
        navigationContent={<MemberAppNavigation_Populated currentPage="profile" />}
      />
    </ModalContextProvider>
  );
};

Profile.propTypes = {
  profileIdOrSlug: string.isRequired
};
