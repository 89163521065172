import {
  CountUp,
  Icons
} from '@paddl/storybook';
import { number, oneOf } from 'prop-types';
import React from 'react';
import {
  TotalViewWrapper,
  TrendWrapper
} from './ProfileViews.styles';

const {
  arrowUp: ArrowUpIcons
} = Icons;

export const ProfileViews = ({
  totalViews,
  trendPercentage,
  trendDirection
}) => {
  const renderTrend = () =>
    <TrendWrapper trendDirection={trendDirection}>
      <ArrowUpIcons />
      <span>{`${trendPercentage}%`}</span>
    </TrendWrapper>;

  return (
        <TotalViewWrapper data-test-id="total-view">
          {totalViews
            ? <>
                <CountUp end={totalViews} />
                {trendPercentage ? renderTrend() : null}
              </>
            : 0}
        </TotalViewWrapper>
  );
};

ProfileViews.propTypes = {
  totalViews: number,
  trendPercentage: number,
  trendDirection: oneOf(['up', 'down', 'sideways'])
};

ProfileViews.defaultProps = {
  totalViews: 0,
  trendPercentage: 0,
  trendDirection: 'up'
};
