import React, { useEffect, useState } from 'react';
import { ProfilesList, SidebarSection, SidebarSectionHeader } from '@paddl/storybook';
import { hardRedirect, isToggleEnabled } from '@paddl/utils-js';
import {
  string
} from 'prop-types';
import { useResource } from '@paddl/utils-react';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';

const PROFILES_IN_SUMMARY = 5;
const TOTAL_PROFILES = PROFILES_IN_SUMMARY * 2;

export const ProfileSuggestions = ({
  profileIdOrSlug
}) => {
  const [profiles, setProfiles] = useState(undefined);
  const version = isToggleEnabled('experience-records') ? 'v2' : 'v1';
  const { data: profileSuggestions, isLoading } = useResource(`${process.env.AT_WORK_API}/${version}/similar-members/profile/${profileIdOrSlug}`);

  useEffect(() => {
    if (isLoading || !profileSuggestions?.profileSuggestions) return;
    setProfiles(profileSuggestions.profileSuggestions);
  }, [profileSuggestions, isLoading]);

  return (
    <SidebarSection>
      <SidebarSectionHeader title="Similar Members" icon={<GroupSharpIcon />} />
    <ProfilesList
      onProfileClick={(id, slug) => hardRedirect('profileBySlugOrId', { id, slug })}
      profiles={profiles?.length ? profiles : undefined}
      profilesInSummary={PROFILES_IN_SUMMARY}
      totalProfiles={TOTAL_PROFILES}
    />
    </SidebarSection>
  );
};

ProfileSuggestions.propTypes = {
  profileIdOrSlug: string.isRequired
};
