import styled from '@emotion/styled';
import { Fonts, Colors } from '@paddl/storybook';

const {
  new_grey
} = Colors;

export const UrlSlugListHeadline = styled.h3`
  ${Fonts.bodyBold}
  color: ${new_grey};
  margin: 0;
`;

export const UseProfileIdWrapper = styled.div`
  margin: 0 0 20px 0;
`;
