import styled from '@emotion/styled';
import { Fonts, Colors } from '@paddl/storybook';

const {
  grey_lighter
} = Colors;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormLabel = styled.p`
  ${({ bold }) => (bold ? Fonts.bodyBold : Fonts.body)};
`;

export const SectionDivider = styled.hr`
  border: 1px solid ${grey_lighter};
  margin: 32px 0;
`;

export const ButtonWrapper = styled.div`
  margin: 0 0 24px 0;
`;
