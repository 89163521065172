import {
  FileSelect,
  Icons
} from '@paddl/storybook';
import {
  func
} from 'prop-types';
import React from 'react';
import {
  InnerWrapper
} from './EditProfileBannerModal.styles';

const AddIcon = Icons.add;

export const BannerFileSelect = ({ onFileSelect }) =>
  (<FileSelect
    callback={onFileSelect}
    isCallbackTypeBase64={false}
    acceptTypes="image/*"
    maxFileSize={10000000}
    maxFileNumber={1}
    isDisabled={false}
  >
    <InnerWrapper>
      <h2><AddIcon /> Select an Image</h2>
      <p>
        Recommended minimum size is 1400x2000px.
        <br />
        Maximum file size is 10mb.
      </p>
    </InnerWrapper>
   </FileSelect>);

BannerFileSelect.propTypes = {
  onFileSelect: func.isRequired
};
