import React from 'react';
import { useHistory } from 'react-router-dom';
import { node } from 'prop-types';
import { PageTabs, getProfileHeroMinHeight, useWidth } from '@paddl/storybook';

const tabSummary = '#tab-summary';
const tabActivities = '#tab-activities';

export const TabContent = ({ activities, summary }) => {
  const { location } = useHistory();
  const width = useWidth();

  return (
    <PageTabs
      data-test-id="page-tabs"
      defaultTab={location.hash}
      usedViewHeight={getProfileHeroMinHeight(width)}
      tabLabels={[
        {
          label: <span>Summary</span>,
          key: tabSummary
        },
        {
          label: <span>Activities</span>,
          key: tabActivities
        }
      ]}
      tabPanels={[
        {
          key: tabSummary,
          item: summary
        },
        {
          key: tabActivities,
          item: activities,
          colorVariant: 'dark'
        }
      ]}
    />
  );
};

TabContent.propTypes = {
  activities: node,
  summary: node
};

TabContent.defaultProps = {
  activities: null,
  summary: null
};
