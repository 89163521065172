import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { TextField, Button, useSnackbarContext } from '@paddl/storybook';
import { withDataSubmission } from '@paddl/utils-react';
import { shape, func } from 'prop-types';
import { contactDetailsFormValidator } from './validator';
import {
  BottomInputsWrappers,
  InputWrapper,
  ButtonsWrapper,
  FieldWrapper
} from '../ProfileSettingsForms.styles';

export const ContactDetailsFormComp = ({
  profileSettings,
  updateProfileSettings: {
    submit: patch
  }
}) => {
  const [profileSettingsForm, setProfileSettingsForm] = useState({
    email: undefined,
    skypeHandle: undefined,
    mobileNumber: undefined
  });

  const { showSnackbar } = useSnackbarContext();

  useEffect(() => {
    setProfileSettingsForm(profileSettings);
  }, [profileSettings]);

  const submitPersonalDetails = async () => {
    try {
      await contactDetailsFormValidator.validate(profileSettingsForm);
      patch(
        `${process.env.API_PREFIX_V2}/profiles/settings`,
        profileSettingsForm,
        'patch'
      );
      showSnackbar({ message: 'Contact details successfully updated.', type: 'success' });
    } catch (e) {
      console.error(e);
      showSnackbar({ message: 'Error updating contact details.', type: 'error' });
    }
  };

  return (
    Object.keys(profileSettings).length ? (
        <>
          <FieldWrapper>
            <TextField
              label="Email"
              placeholder="Email"
              isDisabled
              isControlled
              helperText="This email is used to log in to Paddl."
              value={
                profileSettingsForm.email !== undefined
                  ? profileSettingsForm.email
                  : profileSettings?.email || ''
              }
              onChange={(email) =>
                setProfileSettingsForm({ ...profileSettingsForm, email })}
            />
          </FieldWrapper>
          <BottomInputsWrappers>
            <InputWrapper>
              <FieldWrapper>
                <TextField
                  label="Mobile number"
                  placeholder="Mobile number"
                  isControlled
                  value={
                    profileSettingsForm.mobileNumber !== undefined
                      ? profileSettingsForm.mobileNumber
                      : profileSettings?.mobileNumber || ''
                  }
                  onChange={(mobileNumber) =>
                    setProfileSettingsForm({
                      ...profileSettingsForm,
                      mobileNumber: mobileNumber || null
                    })}
                />
              </FieldWrapper>
            </InputWrapper>
            <InputWrapper>
              <FieldWrapper>
                <TextField
                  label="Skype handle"
                  placeholder="Skype handle"
                  isControlled
                  value={
                    profileSettingsForm.skypeHandle !== undefined
                      ? profileSettingsForm.skypeHandle
                      : profileSettings?.skypeHandle || ''
                  }
                  onChange={(skypeHandle) =>
                    setProfileSettingsForm({
                      ...profileSettingsForm,
                      skype_handle: skypeHandle || null
                    })}
                />
              </FieldWrapper>
            </InputWrapper>
          </BottomInputsWrappers>
          <ButtonsWrapper>
            <Button
              data-test-id="cancel"
              color="white"
              variant="contained"
              onClick={() => setProfileSettingsForm(profileSettings)}
            >
              Cancel
            </Button>
            <Button
              data-test-id="submit"
              variant="contained"
              onClick={submitPersonalDetails}
            >
              Save Changes
            </Button>
          </ButtonsWrapper>
        </>
    ) : (
        <div>Loading settings...</div>
    )
  );
};

ContactDetailsFormComp.propTypes = {
  profileSettings: shape({}),
  updateProfileSettings: shape({
    submit: func.isRequired,
    response: shape({}).isRequired
  }).isRequired
};

ContactDetailsFormComp.defaultProps = {
  profileSettings: {}
};

export const ContactDetailsForm = compose(
  withDataSubmission({ propNameWrapper: 'updateProfileSettings' })
)(ContactDetailsFormComp);
