import styled from '@emotion/styled';
import {
  Colors,
  Fonts,
  borderRadius,
  ActivityColorPalette
} from '@paddl/storybook';

const {
  new_grey,
  plum_electric,
  sec_red_dark,
  charcoal,
  white
} = Colors;

const {
  H3,
  body
} = Fonts;

const getActivityColourByBannerId = (bannerId) => {
  let categoryName = '';

  switch (bannerId) {
    case 1: categoryName = 'education'; break;
    case 2: categoryName = 'employment'; break;
    case 3: categoryName = 'life'; break;
    case 4: categoryName = 'social'; break;
    case 5: categoryName = 'competition'; break;
    case 6: categoryName = 'personal'; break;
    default: categoryName = 'education';
  }

  return ActivityColorPalette[categoryName];
};

export const InnerWrapper = styled.div`
  h2 {
    ${H3};
    color: ${plum_electric};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    ${body};
    font-style: italic;
    text-align: center;
    color: ${new_grey};
  }

  svg {
    width: 16px;
    height: 16px;
    fill: ${plum_electric};
    border: 1px solid ${plum_electric}80;
    border-radius: 50%;
    padding: 6px;
    margin-right: 8px;
  }
`;

export const RemoveButton = styled.button`
  ${body};
  display: block;
  border: none;
  margin: 12px auto;
  background: transparent;
  cursor: pointer;
  color: ${sec_red_dark};

  &:hover {
    text-decoration: underline;
  }
`;

export const ImageEditorWrapper = styled.div`
  @media(min-width: 768px) and (max-width: 960px) {
    margin-left: -16px;
  }
`;

export const DefaultBanner = styled.div`
  height: 140px;
  background-image: url( ${({ imgSourceUrl }) => (imgSourceUrl)});
  border-radius: ${borderRadius};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Headline = styled.h3`
 ${body};
  margin-top: 16px;
  color: ${charcoal};
`;

export const BannersWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isLargeScreen }) =>
    (isLargeScreen ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)')};
  grid-template-rows: 1fr;
  gap: 14px;
  padding-top: 16px;
`;

export const BannerPreview = styled.div`
  height: ${({ isLargeScreen }) => (isLargeScreen ? '56px' : '80px')};
  background-image: url( ${({ imgSourceUrl }) => (imgSourceUrl)});
  opacity: ${({ isSelected }) => (isSelected ? '1' : '.75')};
  border-radius: ${borderRadius};
  border: ${({ isSelected, bannerId }) =>
    (isSelected ? `1px solid ${getActivityColourByBannerId(bannerId)}` : `1px solid ${white}`)};
  pointer-events: ${({ isSelected }) => (isSelected ? 'none' : 'auto')};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: opacity .25s ease,
              border .25s ease;

  &:hover {
    opacity: 1;
  }
`;
