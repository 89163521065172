import { Colors } from '@paddl/storybook';
import _ from 'lodash';

const names = ['purple', 'blue', 'green', 'pink', 'orange', 'red'];

const urls = names.map((name) =>
  `https://vafiquybohuan0wt.public.blob.vercel-storage.com/profile-banners/default/${name}.svg`
);

const borderColours = [
  Colors.sec_purple,
  Colors.sec_blue_dark,
  Colors.sec_green_dark,
  Colors.sec_orange,
  Colors.sec_yellow_dark,
  Colors.sec_red];

export const defaultBanners = _.zipWith(urls, borderColours, names, (url, borderColour, name) => ({
  url, borderColour, id: name
}));
