import React, { useEffect, useRef } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Switch, Route } from 'react-router-dom';
import { routerConfig } from './routerConfig';

export const RouterWrapper = (location) => {
  // Recording the async page view event into Segment
  // Note: recording the very first async page view as Segment natively records page refresh and would result in a double count
  const hasUpdated = useRef(false);

  useEffect(() => {
    // Break out on very first update
    if (!hasUpdated.current) {
      hasUpdated.current = true;

      return;
    }
    // Record a new page view
    // eslint-disable-next-line
    if (window.analytics) window.analytics.page(location.pathname);
  }, [location]);

  return (
    <Switch location={location}>
      {routerConfig.map((route) => <Route
        path={route.path}
        exact={route.exact}
        prefix=""
        component={route.component}
        key="fixed"
      />)}
    </Switch>
  );
};

export const Router = withAuthenticationRequired(RouterWrapper);
