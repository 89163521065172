import styled from '@emotion/styled';
import { Colors } from '@paddl/storybook';

const {
  plum_electric,
  new_grey_light
} = Colors;

export const UrlSlugListWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const UrlSlugListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0;

  svg {
    width: 12px;
    height: 12px;
    fill: ${new_grey_light};
    cursor: pointer;
  }
`;

export const NameIconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    margin-top: 6px;
    margin-right: 6px;
    fill: ${plum_electric};
  }

`;
