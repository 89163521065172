import { Button, Paper } from '@mui/material';
import {
  Colors,
  Column,
  H4,
  LabelText,
  renderLockedBadge,
  Row,
  badgesMetadata
} from '@paddl/storybook';
import PropTypes from 'prop-types';
import React from 'react';
import { useModalContext } from '../../../../components/Context/ModalContext';
import { SelectTypeModal } from '../SelectTypeModal';

const PopuluatedState = ({ selectedVariant }) => {
  const { setOpenModal } = useModalContext();
  const { name, category } = badgesMetadata[selectedVariant];

  return  <Paper sx={{ backgroundColor: Colors.grey_magnolia, padding: '16px' }}>
  <Row>
  {renderLockedBadge(category)}
    <Column style={{ flexGrow: 1, gap: '8px' }}>

    <H4>{name}</H4>
    <LabelText>{category}</LabelText>
    </Column>
  <Button sx={{ backgroundColor: 'white' }} color={category.toLowerCase()} variant="outlined" onClick={() => setOpenModal(SelectTypeModal.modalId)}>Change Experience Type</Button>
  </Row>
          </Paper>;
};
PopuluatedState.propTypes = {
  selectedVariant: PropTypes.isRequired
};

const EmptyState = () => {
  const { setOpenModal } = useModalContext();

  return (
    <Paper sx={{ backgroundColor: Colors.grey_magnolia, padding: '16px' }}>
    <Row>
    {renderLockedBadge('education')}
      <Column style={{ flexGrow: 1, gap: '8px' }}>

      <H4>Unlock a Badge</H4>
      <LabelText>Complete the sections above or select a type...</LabelText>
      </Column>
    <Button sx={{ backgroundColor: 'white' }} variant="outlined" onClick={() => setOpenModal(SelectTypeModal.modalId)}>Select an Experience Type</Button>
    </Row>
    </Paper>
  );
};

export const SelectAVariantSection = ({ experienceVariant }) => {
  return experienceVariant ? <PopuluatedState selectedVariant={experienceVariant} /> : <EmptyState />;
};

SelectAVariantSection.defaultProps = {
  experienceVariant: null
};

SelectAVariantSection.propTypes = {
  experienceVariant: PropTypes.string
};
