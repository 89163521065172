import styled from '@emotion/styled';
import Link from '@mui/material/Link';
import { Fonts } from '@paddl/storybook/lib/Foundation/Fonts/RawFonts';
import { DividedRow } from '@paddl/storybook';
import {
  urlStringForPathKey
} from '@paddl/utils-js';
import { string } from 'prop-types';
import React from 'react';

const SideContentLinksWrapper = styled.div`
  padding-top: 28px;

  .MuiLink-root {
    ${Fonts.labelTextMedium}
  }
`;

export const SideContentLinks = ({ shareLink }) => {
  return (
    <SideContentLinksWrapper>
      <DividedRow>
      <Link
        key="editProfile"
        href={urlStringForPathKey('preferences')}
      >
        Edit Profile URL
      </Link>
      <Link
        key="publicProfile"
        href={shareLink}
        target="_blank"
      >
        View Public Profile
      </Link>
      </DividedRow>
    </SideContentLinksWrapper>
  );
};

SideContentLinks.propTypes = {
  shareLink: string.isRequired
};
