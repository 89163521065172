import React, { useState } from 'react';
import {
  Chip, AutocompleteSelect, Row, Artworks
} from '@paddl/storybook';
import { arrayOf, func, string } from 'prop-types';
import { FormSection } from './FormSection';
// import { getSkillSuggestions } from '@/utils/dataFetching/getSkillSuggestions';

export const SkillsFormSection = ({
  selectedSkills,
  setSelectedSkills
}) => {
  // eslint-disable-next-line no-unused-vars
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isLoadingAutocompleteOptions, setIsLoadingAutocompleteOptions] = useState(false);
  const handleDelete = (deletedSkill) => {
    setSelectedSkills(selectedSkills.filter((skill) => skill !== deletedSkill));
  };

  const addSkill = (skill) => {
    if (!selectedSkills.includes(skill)) {
      setSelectedSkills([...selectedSkills, skill]);
    }
  };

  // const updateAutocompleteOptions = async (partialSkill = '') => {
  //   try {
  //     setIsLoadingAutocompleteOptions(true)

  //     const suggestions = (await getSkillSuggestions(partialSkill))
  //       .filter((option) => option !== partialSkill)

  //     if (partialSkill) suggestions.unshift(partialSkill)

  //     setAutocompleteOptions(suggestions)
  //   } catch (err) {
  //     // eslint-disable-next-line no-console
  //     console.error(err)
  //   }
  //   setIsLoadingAutocompleteOptions(false)
  // }
  const onValueChange = (newValue) => {
    if (currentValue !== newValue && !isLoadingAutocompleteOptions) {
      // updateAutocompleteOptions(newValue)
      setCurrentValue(newValue);
    }
  };

  const renderSelectedSkills = () => {
    return selectedSkills.map((skill) =>
      <Chip
        label={skill}
        action={() => handleDelete(skill)}
        key={skill}
      />);
  };

  return (
    <FormSection icon={<Artworks.skills />}>
          <AutocompleteSelect
            label="Enter a skill name"
            action={(value) => addSkill(value)}
            defaultValue={null}
            options={autocompleteOptions}
            getInputValue={(value) => onValueChange(value)}
            resetFieldAfterAction
          />
      <Row style={{ flexWrap: 'wrap', minHeight: '128px', alignItems: 'flex-start' }}>
          {renderSelectedSkills()}
      </Row>
    </FormSection>
  );
};

SkillsFormSection.propTypes = {
  selectedSkills: arrayOf(string).isRequired,
  setSelectedSkills: func.isRequired
};
