import styled from '@emotion/styled';
import { Colors, Fonts, Elevations } from '@paddl/storybook';
import Menu from '@mui/material/Menu';

const {
  white,
  new_grey_dark,
  new_grey_light,
  grey_athens
} = Colors;

export const ShowMoreIconWrapper = styled.div`
  button {
    transition: background 0.25s ease;
  }
`;

export const StyledMuiMenu = styled(Menu)`.custom-paper {
  &.MuiPaper-root {
    background-color: ${white};
    box-shadow: ${Elevations.elevation};
    margin-top: 42px;
  }
}

.custom-list {
  &.MuiList-root {
    &.MuiList-padding {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.icon-button {
  &.MuiMenuItem-root {
    padding-top: 16px;
    padding-bottom: 16px;

    .MuiListItemIcon-root {
      min-width: 0;
    }

    .MuiListItemText-root {
      margin-left: 8px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.icon-button {
  &.MuiListItem-button {
    transition: background 0.25s ease;

    &:hover {
      background-color: ${grey_athens};
    }
  }
}
.MuiTypography-root {
  &.MuiListItemText-primary {
    ${Fonts.body};
    color: ${new_grey_dark};
  }
}

.MuiListItemIcon-root {
  svg {
    fill: ${new_grey_light};
  }
}`;
